<template>
  <div v-if="$store.state.me.role !== 'partner'" class="lsg-cabinet">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__content">
      <form class="lk" autocomplete="off" @submit.prevent="saveCompany">
        <div class="lk__inputs">
          <div v-if="!disabled" class="lk__field">
            <label>Ответственный:</label>
            <v-select :value="{ label: fields.responsible_user_name, value: fields.responsible_user_id }" :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" placeholder="" @input="onUserChange">
              <template slot="no-options">Нет результатов</template>
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select>
          </div>
          <div class="lk__field">
            <label for="name">Наименование:</label>
            <input type="text" v-model="fields.name" id="name">
          </div>
          <div class="lk__field">
            <label>Теги:</label>
            <TagsInput @input="onTagsInput"/>
          </div>
          <div class="lk__field">
            <label for="email">E-mail:</label>
            <input type="email" id="email" v-model="fields.email">
          </div>
          <div class="lk__field">
            <label for="inn">ИНН:</label>
            <input type="text" id="inn" v-model="fields.inn">
          </div>
          <div class="lk__field">
            <label>Телефоны:</label>
            <div class="phones">
              <div class="phones__item" v-for="(phone, index) in fields.phones" :key="index">
                <div class="phones__phone">
                  <input type="text" v-model="phone.value">
                </div>
                <div class="small-buttons">
                  <button class="btn btn--small" type="button" v-if="index !== 0" @click="removePhone($event, index)">
                    <MinusIco/>
                  </button>
                  <button class="btn btn--small" type="button" @click="addPhone">
                    <PlusIco/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" class="btn">ДОБАВИТЬ</button>
        </div>
        <div class="lk__back">
          <CompanyBack/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CompanyBack from '@/components/svg/CompanyBack'
import DownIcon from '@/components/svg/DownIcon'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'
import PlusIco from '@/components/svg/plus'
import MinusIco from '@/components/svg/minus'
// import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'
import ADD_COMPANY from '@/graphql/mutations/AddCompany.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'AddCompany',
  components: {
    MinusIco,
    PlusIco,
    TagsInput,
    DownIcon,
    CompanyBack
  },
  data () {
    return {
      title: 'Добавить компанию',
      disabled: true,
      fields: {
        name: '',
        email: '',
        responsible_user_id: '',
        responsible_user_name: '',
        inn: '',
        group_id: '',
        phones: [
          {
            value: ''
          }],
        tags: []
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
    })
  },
  created () {
    this.fields.responsible_user_id = this.$store.state.me._id
    this.fields.responsible_user_name = this.$store.state.me.name
    this.disabled = this.$store.state.me.role === 'manager'
  },
  methods: {
    getCurrentPhone () {
      let currentPhones = []

      this.fields.phones.forEach(phone => {
        if (phone.value) {
          currentPhones.push(phone.value)
        }
      })

      return currentPhones
    },
    saveCompany () {
      let currentInput = {
        name: this.fields.name,
        email: this.fields.email,
        inn: this.fields.inn,
        group_id: this.fields.group_id,
        responsible_user_id: this.fields.responsible_user_id,
        phone: this.getCurrentPhone(),
        tags: this.fields.tags
      }

      this.$apollo.mutate({
        mutation: ADD_COMPANY,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data && data.addCompany && data.addCompany._id) {
            this.$notify({
              group: 'lsg-notify',
              text: 'Контрагент успешно создан'
            })
            this.$router.push({ path: `/companies/${data.addCompany._id}` })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
          if (!/Данный ИНН зарегистрирован у пользователя/.test(item)) {
            this.$notify({
              group: 'lsg-notify-support',
              title: 'Сообщить в поддержку',
              text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
              type: 'info',
              closeOnClick: true,
              data: {
                info: {
                  project: 'Личный кабинет Leasing-trade',
                  name: this.$store.state.me.name,
                  email: this.$store.state.me.email,
                  group_id: this.$store.state.me.group.id,
                  role: this.$store.state.me.role,
                  localTime: new Date(),
                  system: navigator.userAgent,
                  url: window.location.href,
                  window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                  error: item,
                  comment: 'Ошибка при создании компании'
                },
                style: 'cursor: pointer'
              }
            })
          }
        })
      })
    },
    addPhone () {
      this.fields.phones.push({ value: '' })
    },
    removePhone (e, index) {
      e.preventDefault()

      this.fields.phones.splice(index, 1)
    },
    async callPhone (phone) {
      if (!phone || !this.$store.state.me.workphone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не указан телефон'
        })
        return
      }
      const callWindow = window.open(`http://192.168.10.3/amocrm/amocrm.php?_login=amocrm&_secret=mWYd1BU9hmWuezov&_action=call&to=${phone}&as=Amocrm&from=${this.$store.state.me.workphone}&rand=0.9550694509986963`)
      setTimeout(() => {
        callWindow.close()
      }, 1_000)
    },
    onTagsInput (tags) {
      this.fields.tags = tags.map(item => item.value)
    },
    onUserChange (e) {
      if (e) {
        this.fields.responsible_user_id = e.value
        this.fields.responsible_user_name = e.label
      } else {
        this.fields.responsible_user_id = null
        this.fields.responsible_user_name = null
      }
    }
  }
}
</script>
